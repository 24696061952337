import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { serverURL } from './config';

import { TextField, Button } from '@material-ui/core';

import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


import axios from 'axios';


import logo from './logo-no-background.png';
import backgroundImage from './inmed_background.png';
import loginLogo from './inmed_login_logo.svg';
import logoWhite from './inmed_studio_logo_white.png';

import './Login.css';

export default function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        axios.post(`${serverURL}/api/v1/login`, formData)
        .then((response) => {
            console.log(response, 'response');
            let data = response.data;
            if (data.status === "error") {
                setError(data.message);
                return;
            }
            localStorage.setItem('token', data.token);
            localStorage.setItem('username', username);
            navigate('/dashboard');
        }).catch((error) => {
            console.log(error, 'error', error.response);
            setError(error.response.data.error);
        });
    };

    // useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         fetch(`${serverURL}/verify`, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 token: localStorage.getItem('token'),
    //             }),
    //         })
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 console.log(data, 'data');
    //                 if (data.error) {
    //                     setError(data.error);
    //                 } else {
    //                     // redirect to home page
    //                     navigate('/');
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err, 'err');
    //                 // setError(err);
    //             });
    //     }
    // }, 
    // []);


    return (
        <div className="root" style={{background: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="background-logo-text-wrapper">
                <img src={logoWhite} style={{ width: '500px', height: '200px', marginBottom: '10px', }} />
                <h3 className="background-logo-text">We are a proven Leader In AI Based medical Imaging and Radiology Solutions. Explore our FDA-Cleared AI-Powered tools enhanced by Advance Research and Human-centered Design.</h3>
            </div>
            <div className="slant-container"></div>
            <div className="login-container">
            <img src={loginLogo} style={{ width: '250px', height: '150px', marginBottom: '10px', }} />
            <h1 className="login-title">Inmed AI OncoShield Login</h1>
            <h3 className="welcome-title">Welcome, please login to continue</h3>
                <TextField
                    label="Username"
                    variant="outlined"
                    value={username}
                    className="login-input"
                    style={{marginBottom: '10px'}}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    className="login-input"
                    style={{marginBottom: '10px'}}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{ // <-- Esto es nuevo
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button 
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    className="login-button"
                >Login</Button>

                <p className="error-text">{error}</p>
            </div>
        </div>
    );
}         