import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Jobs from "./Jobs";
import Login from "./Login";
import Viewer from './Viewer';
import Upload from './Upload';
import theme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';


function App() {
  return (
      <ThemeProvider theme={theme}>
      <div className="app" style={{width: '100%', height: '100%'}}>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/dashboard" element={<Jobs />} /> 
            <Route exact path="/upload" element={<Upload />} /> 
            <Route exact path="/viewer/:record_id" element={<Viewer />} />
            <Route exact path="/" element={<Navigate to="/dashboard"/>}/>
          </Routes>
        </Router>
      </div>
      </ThemeProvider>
      
  );
}
export default App; 
