import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
import './Upload.css';
import axios from 'axios';
import { serverURL } from './config';

export default function Upload() {
    const [files, setFiles] = useState([]);
    const [recordId, setRecordId] = useState('');
    const [uploaded, setUploaded] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
    });

    const onStartJob = () => {
        let formData = new FormData();
        formData.append('record_id', recordId);
        axios.post(`${serverURL}/api/v1/start_job`,
            formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        }).then(res => {
            console.log(res);
            alert('Job started');
        }).catch(err => {
            console.log(err);
        });
    }

    const onFilesUpload = () => {
        let fromData = new FormData();
        for (let i = 0; i < files.length; i++) {
            fromData.append('files', files[i]);
        }
        setUploading(true);
        axios.post(`${serverURL}/api/v1/upload`, fromData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then(res => {
            console.log(res);
            setRecordId(res.data.record_id);
            setUploaded(true);
        }).catch(err => {
            console.log(err);
        })
            .finally(() => {
                setUploading(false);
            });
    };

    return (
        <div className="root">
            <Header />
            <div className="upload-container">
                <h1>Upload Dicoms</h1>
                <div {...getRootProps()}
                    className="dropzone"
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div>
                <p className="note">Note: Only .dcm files are accepted</p>
                <h2>Files Selected</h2>
                <ul>
                    {files.slice(0, 10).map(file => (
                        <li key={file.name}>
                            {file.name} - {file.size} bytes
                        </li>
                    ))}
                </ul>
                {
                    files.length > 10 ? <p>... and {files.length - 10} more</p> : null
                }
                {
                    uploading ? <CircularProgress
                        value={uploadProgress}
                    /> : null
                }
                {uploaded ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStartJob}
                        className="start-button"
                    >
                        Start
                    </Button> :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onFilesUpload}
                        className="uploader-upload-button"
                        disabled={files.length === 0}
                    >
                        Upload
                    </Button>
                }
            </div>
        </div>

    );
}