import * as React from 'react';
import { useParams } from 'react-router-dom';


import { Buffer } from 'buffer';

import {
    Box,
    CircularProgress,
    Select,
    Slider,
    Typography,
    MenuItem
} from '@mui/material';


import {
    VisibilityOff as VisibilityOffIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material';

import { ColorPicker } from 'material-ui-color';
import { PNG } from 'pngjs/browser';

import { serverURL } from "./config";
import './Viewer.css';

import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import Header from './Header';

const rgbToHex = (rgb) => {
    return '#' + rgb.map(x => {
        return ('0' + x.toString(16)).slice(-2);
    }
    ).join('');
}

const VisibilityOnOff = (props) => {
    const [visible, setVisible] = React.useState(props.visible);

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);
    
    if (visible) {
        return <VisibilityIcon
            key={props.key}
            onClick={
                () => {
                    setVisible(false);
                    props.onVisibilityChange(props.name, false);
                }
            } />
    } else {
        return <VisibilityOffIcon
            key={props.key}
            onClick={
                () => {
                    setVisible(true);
                    props.onVisibilityChange(props.name, true);
                }
            } />
    }
}


export default function Viewer(props) {

    const { record_id } = useParams();
    const [fetched, setFetched] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [record, setRecord] = React.useState({});
    const [imagesFetchedList, setImagesFetchedList] = React.useState({});
    const [imageData3D, setImageData3D] = React.useState(null);
    const [labelsImages, setLabelsImages] = React.useState({});
    const [labelValToLabels, setLabelValToLabels] = React.useState({});
    const [labelVisiblity, setLabelVisiblity] = React.useState({});
    const [labelsColors, setLabelsColors] = React.useState({});
    const [windowVals, setWindowVals] = React.useState([40, 400]);
    const [numLoaded, setNumLoaded] = React.useState(0);
    const [imgSize, setImgSize] = React.useState([0, 0]);
    const [numImages, setNumImages] = React.useState(0);
    const [models, setModels] = React.useState([]);
    const [model, setModel] = React.useState('');
    const [labels, setLabels] = React.useState({});
    const [loggedIn, setLoggedIn] = React.useState(true);
    const [sliceNumber, setSliceNumber] = React.useState(0);

    const navigate = useNavigate();

    // check if logged in
    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, []);

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        // get record data
        axios.get(`${serverURL}/api/v1/record/${record_id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                setRecord(res.data);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    setLoggedIn(false);
                }
            });
        axios.get(`${serverURL}/api/v1/view_metadata/${record_id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                setModels(res.data.models);
                setModel(res.data.models[0]);
                setImgSize(res.data.img_size);
                setNumImages(res.data.num_images);
                setWindowVals(res.data.window || [40, 400]);

                let labelsColors = res.data.labels_colors;
                let labelsVals = res.data.labels_vals;
                let labelsState = {};
                let labelValToLabels = {};
                let labels = {}
                for (let model of Object.keys(labelsColors)) {
                    labelsState[model] = {};
                    labelValToLabels[model] = {};
                    // for (let label of Object.keys(labelsColors[model])) {
                    let modelLabels = Object.keys(labelsColors[model]);
                    labels[model] = modelLabels;
                    for (let i = 0; i < modelLabels.length; i++) {
                        // labelsState[model][label] = true;
                        let label = modelLabels[i];
                        labelsState[model][label] = false;
                        // labelValToLabels[model][i] = label;
                        labelValToLabels[model][labelsVals[model][label]] = label;
                    }
                    // labelsState[model]['all'] = false;
                }
                setLabelsColors(labelsColors);
                setLabelVisiblity(labelsState);
                setLabelValToLabels(labelValToLabels);
                setLabels(labels);

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    setLoggedIn(false);
                }
            });
    }, [serverURL]);

    React.useEffect(() => {
        const setLabelData = (body, name) => {
            new PNG().parse(body, function (err, data) {
                if (err) {
                    console.log(err);
                }
                else {
                    setLabelsImages(labelsImages => ({
                        ...labelsImages,
                        [name]: data.data
                    }));
                }
            });
        }
        const setImageData = (body, i) => {
            let pixelData = new Uint8Array(body);

            setNumLoaded(prevNumLoaded => prevNumLoaded + 1);
            setImagesFetchedList(prevImagesFetchedList => ({
                ...prevImagesFetchedList,
                [i]: pixelData,
            }));
            if (i === 20) setFetched(true);
        }

        const fetchImages = async () => {
            for (let model of models) {
                try {
                    const response = await fetch(`${serverURL}/api/v1/view_label/${record_id}/${model}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
                    setLabelData(await response.arrayBuffer(), model);
                } catch (err) {
                    handleError(err);
                }
            }
            let imageURL = `${serverURL}/api/v1/view/${record_id}`;
            let i = 0;
            try {
                const response = await fetch(imageURL,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                const reader = response.body.getReader();
                let res;
                res = await reader.read();
                let buffer = new Buffer(res.value.slice(9));
                let frame;
                while (true) {
                    let indexOf = new Buffer(buffer).indexOf('--axaxa--');
                    if (indexOf > -1) {
                        frame = buffer.slice(0, indexOf);
                        buffer = buffer.slice(indexOf + 9);
                        setImageData(frame, i);
                        i += 1;
                    }
                    res = await reader.read();
                    if (res.done) {
                        break;
                    }
                    buffer = Buffer.concat([buffer, new Buffer(res.value)]);
                }
                while (true) {
                    // what is rest of buffer...
                    let indexOf = new Buffer(buffer).indexOf('--axaxa--');
                    if (indexOf > -1) {
                        frame = buffer.slice(0, indexOf);
                        buffer = buffer.slice(indexOf + 9);
                        setImageData(frame, i);
                        i += 1;
                    } else {
                        setImageData(buffer, i);
                        break;
                    }
                }

            } catch (err) {
                console.log(err, 'err');
            }
        }
        if (numLoaded === 0 && numImages > 0 && !fetched) fetchImages();
    }, [numLoaded, numImages]);

    React.useEffect(() => {
        if (numLoaded === numImages && numImages > 0) {
            setImageData3D(new Uint8Array(imgSize[0] * imgSize[1] * numImages * 2));
            let newData = new Uint8Array(imgSize[0] * imgSize[1] * numImages * 2);
            for (let j = 0; j < Object.keys(imagesFetchedList).length - 1; j++) {
                if (j in imagesFetchedList) {
                    newData.set(imagesFetchedList[String(j)], j * imgSize[0] * imgSize[1] * 2);
                } else {
                    console.log('not in', j);
                }
            }
            setImageData3D(newData);
            setSliceNumber(parseInt(numImages / 2));
            setLoaded(true);

        }
    }, [numLoaded, imagesFetchedList]);


    const extractHU = (lowerByte, higherByte) => {
        let val = higherByte * 256 + lowerByte;
        val = val - 1024;
        return val;
    }


    const onChangeWheel = (e) => {
        e.preventDefault();
        if (e.deltaY > 0) {
            const val = Math.min(sliceNumber + 1, numImages);
            setSliceNumber(val);
        }
        else {
            const val = Math.max(sliceNumber - 1, 0);
            setSliceNumber(val);
        }
    }


    const applyWindow = (val, wl, ww) => {
        if (val < wl - ww / 2) return 0;
        if (val > wl + ww / 2) return 255;
        return 255 * (val - (wl - ww / 2)) / ww;
    }

    const plotImage = (imageData) => {

        if (imageData == null)
            return;
        if (numLoaded < numImages)
            return;

        const overlayLabels = (overlaidImageData, labelIndex, imgIndex) => {
            for (let model of models) {
                let labelVal = labelsImages[model][labelIndex];

                if (labelVal < 1 || !(labelVal in labelValToLabels[model]) || labelVisiblity[model][labelValToLabels[model][labelVal]] === false) {
                    continue;
                }
                let color = labelsColors[model][labelValToLabels[model][labelVal]];
                overlaidImageData[imgIndex] = overlaidImageData[imgIndex] * 0.5 + color[0] * 0.5;
                overlaidImageData[imgIndex + 1] = overlaidImageData[imgIndex + 1] * 0.5 + color[1] * 0.5;
                overlaidImageData[imgIndex + 2] = overlaidImageData[imgIndex + 2] * 0.5 + color[2] * 0.5;
            }
            return overlaidImageData;
        }

        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let overlaidImageData;
        overlaidImageData = new Uint8Array(imgSize[0] * imgSize[1] * 4);
        const offset = sliceNumber * imgSize[0] * imgSize[1] * 2;
        const labelOffset = sliceNumber * imgSize[0] * imgSize[1] * 4;

        for (let i = 0; i < imgSize[0]; i++) {
            for (let j = 0; j < imgSize[1]; j++) {
                let outputIndex = (i * imgSize[1] + j) * 4;
                let inputIndex = (i * imgSize[1] + j) * 2;
                let val = extractHU(
                    imageData[offset + inputIndex],
                    imageData[offset + inputIndex + 1]
                );
                val = applyWindow(val, windowVals[0], windowVals[1]);

                overlaidImageData[outputIndex] = overlaidImageData[outputIndex + 1] = overlaidImageData[outputIndex + 2] = val;
                overlaidImageData[outputIndex + 3] = 255;
                overlaidImageData = overlayLabels(overlaidImageData, labelOffset + outputIndex, outputIndex);
            }
        }
        let img = new ImageData(new Uint8ClampedArray(overlaidImageData), imgSize[1], imgSize[0]);
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.putImageData(img, 0, 0);
        imgRef.src = canvas.toDataURL('image/jpeg', 0.98);
    }

    React.useEffect(() => {
        if (numLoaded >= numImages) {
            plotImage(imageData3D);
        }
    }, [fetched, sliceNumber, imagesFetchedList, loaded, labelVisiblity]);

    if (!loggedIn) {
        navigate('/login');
    }

    let imgRef = React.useRef(null);

    return (
        <div className="viewer-root">
            <Header />
            <div className="body">
                <div className="options">
                    <table className="patient-details-table">
                        <tbody>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient Details</td>
                                <td>{record_id.slice(0, 8)}...</td>
                            </tr>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient ID:</td>
                                <td>{record.patient_id}</td>
                            </tr>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient Name:</td>
                                <td>{record.patient_name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <Select
                        fullWidth
                        className="model-select"
                        value={model}
                        onChange={(e) => {
                            setModel(e.target.value);
                            // setModelDownload(e.target.value);
                        }}
                    >
                        {
                            Object.keys(labelsColors).map((model, i) => {
                                return (
                                    <MenuItem key={i} value={model}>
                                        {model}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>

                    <div>

                        {
                            model in labels &&
                            Object.entries(labels[model]).slice(1).map((labelValAndLabel, index) => {
                                const label = labelValAndLabel[1];
                                return (
                                    <div className="label-option" key={index}>
                                        <h4 style={{
                                            color: rgbToHex(labelsColors[model][label]),
                                            paddingRight: '5px',
                                            flexGrow: 4,
                                        }}>
                                            {label.toUpperCase()}
                                        </h4>
                                        <VisibilityOnOff
                                            style={{ flexGrow: 1 }}
                                            name={label}
                                            visible={
                                                labelVisiblity[model][label]
                                            }
                                            onVisibilityChange={
                                                (label, val) => {
                                                    setLabelVisiblity(prevState => {
                                                        return {
                                                            ...prevState,
                                                            [model]: {
                                                                ...prevState[model],
                                                                [label]: val
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        />
                                        <ColorPicker
                                            style={{ flexGrow: 1 }}
                                            name={label + 'Color'}
                                            value={rgbToHex(labelsColors[model][label])}
                                            hideTextfield={true}
                                            deferred={true}
                                            onChange={
                                                (color) => {
                                                    setLabelsColors(prevState => {
                                                        return {
                                                            ...prevState,
                                                            [model]: {
                                                                ...prevState[model],
                                                                [label]: color.rgb
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        />
                                    </div>
                                );
                            })


                        }
                    </div>

                </div>
                {loaded ? (

                    <div className="container">

                        <div className="image-container">
                            <div style={{ color: 'white' }}>
                                {sliceNumber}/{numImages}
                            </div>
                            <img
                                id="img"
                                ref={node => { imgRef = node }}
                                width="90%"
                                height="90%"
                                className="img"
                                onWheel={(e) => {
                                    onChangeWheel(e);
                                }}
                            />
                            <Slider
                                className="slider"
                                color="secondary"
                                value={sliceNumber}
                                min={0}
                                max={numImages - 1}
                                onChange={(e, val) => {
                                    setSliceNumber(val);
                                }} />
                        </div>
                    </div>

                ) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress
                                value={numLoaded / numImages * 100}
                                variant="determinate"
                                color="secondary"
                                size={60}
                            >
                            </CircularProgress>
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="white"
                                >{`${Math.round(numLoaded / numImages * 100)}%`}</Typography>
                            </Box>
                        </Box>
                    </div>
                )
                }
            </div>
        </div>
    );
}