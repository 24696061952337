import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    CircularProgress
} from '@material-ui/core';
import {
    Typography,
    Button,
} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { FaSearch } from 'react-icons/fa';
import { serverURL } from './config';
import { useNavigate } from 'react-router-dom'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
// import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';

import Header from './Header';

import './Jobs.css';

const DeleteDialog = (props) => {
    const { onClose, selectedValue, open } = props;


    const handleClose = () => { console.log('closing'); }

    return (
        <Dialog className='delete-dialog'
            PaperProps={{
                style: {
                    padding: '10px',
                    margin: '10px'
                }
            }}
            onClose={handleClose} open={open}
        >
            <DialogTitle style={{ color: 'black', fontSize: '16pt', fontWeight: '800' }}>Delete Record</DialogTitle>
            <h5 className="are-you-sure-text">Are you sure you want to delete Patient(s) with recordId(s) {props.recordId}?</h5>
            <div className='delete-dialog-buttons-wrapper'>
                <Button
                    className='delete-button'
                    variant="contained"
                    onClick={() => {
                        console.log('deleted', props.patientId, props.uniqueId);
                        // let data = {
                        //     'uniqueId': props.uniqueId,
                        // }
                        let url = `${serverURL}/api/v1/record/${props.recordId}`;
                        axios.delete(url,
                            {
                                headers: {
                                    'authorization': `Bearer ${localStorage.getItem('token')}`
                                }
                            })
                            .then(res => {
                                console.log('res', res);
                                // toast.success(res.message);
                                props.onClose();
                            })
                            .catch(err => {
                                console.log('err', err);
                                // toast.error(err.message || 'Something went wrong!');
                            })
                            .finally(() => {
                                props.onClose();
                            });
                    }}
                >Delete</Button>
                <Button
                    className='cancel-button'
                    variant="contained"
                    onClick={() => {
                        console.log('cancelled');
                        props.onClose();
                    }}
                >Cancel</Button>

            </div>
        </Dialog>
    );
}

function Status(props) {
    const { status } = props;
    let useClassName = '';
    let useCircleClassName = '';
    if (status.toLowerCase() === 'completed') {
        useClassName = 'status completed';
        useCircleClassName = 'circle completed-circle';
    } else if (status.toLowerCase() === 'failed') {
        useClassName = 'status failed';
        useCircleClassName = 'circle failed-circle';
    } else {
        useClassName = 'status in-progress';
        useCircleClassName = 'circle in-progress-circle';
    }
    return (
        <div className={useClassName}>
            <div className={useCircleClassName}></div>
            <span className='status-text'>{status}</span>
        </div>
    );

}

function TableRecord(props) {
    const { record } = props;
    const recordClasses = record.lesion_volumes?.length > 0 ? 'record-table-row lesion-row' : 'record-table-row';
    return (
        <TableRow className={recordClasses}>
            {/* <TableCell className='record-table-cell'>
                <Checkbox
                    color='white'
                    // select color
                    checked={record.checked}
                    onChange={(e) => {
                        props.onCheckChange(e.target.checked);
                    }}
                />
            </TableCell> */}
            <TableCell className='record-table-cell'>
                {record.record_id}
            </TableCell>
            <TableCell className='record-table-cell'>
                {record.patient_id}
            </TableCell>
            <TableCell className='record-table-cell'>
                {record.patient_name}
            </TableCell>
            <TableCell className='record-table-cell'>
                {record.patient_sex}
            </TableCell>
            <TableCell className='record-table-cell'>
                {record.patient_age}
            </TableCell>
            <TableCell className='record-table-cell'>
                {record.study_date}
            </TableCell>
            <TableCell className='record-table-cell'>
                <Status
                    status={record.status}
                />
            </TableCell>
            <TableCell className='record-table-cell'>

                {record.status.toLowerCase() === 'completed' ? <>
                    <GetAppIcon className='download-icon'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            console.log('Downloading....')
                            axios.get(`${serverURL}/api/v1/report/${record.record_id}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                                    },
                                    responseType: 'blob'
                                })
                                .then(res => {
                                    console.log('res', res);
                                    const url = window.URL.createObjectURL(new Blob([res.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', `Report_${record.record_id}.pdf`);
                                    document.body.appendChild(link);
                                    link.click();


                                });
                        }}
                    />
                    <VisibilityIcon
                        className="viewer-icon"
                        onClick={(e) => {
                            // navigate(`/viewer/${record.record_id}`);
                            props.onViewerClick(record.record_id);
                        }}
                    />
                </> : null
                }
                <DeleteForeverIcon
                    className="delete-icon"
                    onClick={(e) => {
                        console.log('Deleting....')
                        props.onDeleteClick();
                    }}
                />

            </TableCell>
            {/* <TableCell className='record-table-cell'
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >

            </TableCell> */}
        </TableRow>
    );
}

export default function PredictionList(props) {

    const [records, setRecords] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [numRecords, setNumRecords] = useState(0);

    const [notLoggedIn, setNotLoggedIn] = useState(false);
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRecordId, setDeleteRecordId] = useState('');
    const [deleteUniqueId, setDeleteUniqueId] = useState('');
    const [tableTab, setTableTab] = useState('worklist');

    const [filters, setFilters] = useState({});

    const [dateType, setDateType] = useState('text');

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const getData = async (newFilters) => {
        setLoading(true);
        try {
            const offset = page * rowsPerPage;
            const fetchedRecords = await axios.post(`${serverURL}/api/v1/records/${offset}/${rowsPerPage}`, newFilters ? newFilters : filters,
                {
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
            console.log('fetchedRecords', fetchedRecords);
            if (fetchedRecords.data.status === "error" && fetchedRecords.data.message === "Invalid token") {
                // props.history.push('/login');
                setNotLoggedIn(true);
            }
            // set checked to false
            fetchedRecords.data.forEach((record) => {
                record.checked = false;
            });
            setRecords(fetchedRecords.data);
        } catch (err) {
            console.log(err.response.data, 'err response');
        }
        setLoading(false);
    }
    const getNumRecords = async () => {
        try {
            const numRecords = await axios.get(`${serverURL}/api/v1/records_count`, {
                data: {
                    'filters': filters,
                },
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log('numRecords', numRecords);
            if (numRecords.data.status === "error" && numRecords.data.message === "Invalid token") {
                // props.history.push('/login');
                setNotLoggedIn(true);
            }
            // setRowsPerPage(numRecords.data);
            setNumRecords(numRecords.data.count);
        } catch (err) {
            console.log(err.response.data, 'err response');
        }

    }
    useEffect(() => {
        getNumRecords();
        getData();
    }, [page, rowsPerPage]);

    const changeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const changePage = (event, newPage) => {
        setPage(parseInt(newPage, 10));
    }

    // call api once filters are changed
    // React.useEffect(() => {
    //     getData();
    // }, [filters]);

    const handleAutoRefresh = (e) => {
        if (e.target.checked) {
            console.log("enable auto refresh")
            const ri = setInterval(function () {
                getData();
            }, 1000 * 5)
            setRefreshInterval(ri)
        } else {
            console.log("disable auto refresh", refreshInterval)
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        }
    }

    if (notLoggedIn) {
        navigate('/login');
    }

    return (
        <div className="root">
            <Header />

            <div className="table-container">

                <div className="table-tab-select-row">
                    <button className={tableTab === 'worklist' ? "table-tab-select active" : "table-tab-select"}
                        onClick={() => {
                            setTableTab('worklist');
                            // remove status key from filters
                            const newFilters = { ...filters };
                            delete newFilters.status;
                            setFilters(newFilters);
                            getData(newFilters);
                        }}>Worklist</button>
                    <button className={tableTab === 'completed' ? "table-tab-select active" : "table-tab-select"}
                        onClick={() => {
                            setTableTab('completed');
                            setFilters({ ...filters, 'status': 'Completed' });
                            getData({ ...filters, 'status': 'Completed' });
                        }}>Completed</button>
                    <button className={tableTab === 'in-progress' ? "table-tab-select active" : "table-tab-select"}
                        onClick={() => {
                            setTableTab('in-progress');
                            setFilters({ ...filters, 'status': 'in-progress' });
                            getData({ ...filters, 'status': 'in-progress' });
                        }}>In-Progress</button>
                </div>
                <div className="table-buttons-row">
                    <button
                        onClick={() => {
                            getData();
                        }}
                        className="refresh-button"
                        variant="contained"
                    >
                        <RefreshIcon style={{ width: "20px", height: "20px" }} /> <span>Refresh</span>
                    </button>
                    <button
                        onClick={() => {
                            // getData();
                            navigate('/upload');
                        }}
                        className="upload-button"
                        variant="contained"
                    >
                        <AddIcon style={{ width: "20px", height: "20px" }} /> <span>Upload Study</span>
                    </button>
                </div>
                <div className="table-search-row">
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            placeholder="Record ID"
                            className="table-search"
                            onChange={(e) => {
                                console.log('searching...', e.target.value);
                                setFilters({ ...filters, 'record_id': e.target.value });
                            }}
                        />
                        <FaSearch style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            color: '#ccc'
                        }}
                            onClick={() => {
                                getData();
                            }}

                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            placeholder="Patient ID"
                            className="table-search"
                            onChange={(e) => {
                                console.log('searching...', e.target.value);
                                setFilters({ ...filters, 'patient_id': e.target.value });
                            }}
                        />
                        <FaSearch style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            color: '#ccc'
                        }}
                            onClick={() => {
                                getData();
                            }}
                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            placeholder="Age"
                            className="table-search"
                            onChange={(e) => {
                                setFilters({ ...filters, 'patient_age': e.target.value });
                            }}
                        />
                        <FaSearch style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            color: '#ccc'
                        }}
                            onClick={() => {
                                getData();
                            }}
                        />
                    </div>
                    <select

                        onChange={(e) => {
                            console.log('searching...', e.target.value);
                            setFilters({ ...filters, 'patient_sex': e.target.value });
                            getData();
                        }}
                        style={{
                            'padding': '10px',
                            'marginLeft': '10px',
                            'fontSize': '12pt',
                            'borderRadius': '5px',
                            'border': 'none',
                            'paddingLeft': '20px',
                            'height': '40px',
                            'background': 'white',
                            'width': '150px',
                            'color': '#aaa'
                        }}
                    >
                        <option value="">Sex</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <input
                        type={dateType}
                        placeholder="Study Date"
                        className="table-search"
                        onFocus={() => setDateType('date')}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                setDateType('text');
                            }
                        }}
                        onChange={(e) => {
                            console.log('searching...', e.target.value);
                            setFilters({ ...filters, 'study_date': e.target.value });
                        }}
                        style={{
                            'padding': '10px',
                            'marginLeft': '10px',
                            'fontSize': '12pt',
                            'borderRadius': '5px',
                            'border': 'none',
                            'paddingLeft': '20px',
                            'height': '20px',
                            'background': 'white',
                            'width': '150px',
                            'color': '#aaa'
                        }}
                    />


                </div>
                <Table stickyHeader className='record-table'>
                    <TableHead className='record-table-head'>
                        <tr className='record-table-header-row'>
                            {/* <TableCell size='small' className='record-table-cell'>
                                    <Checkbox color='white'
                                        checked={props.checked}
                                        onChange={(e) => {
                                            // set all checked to false
                                            const newRecords = [...records];
                                            records.forEach((record) => {
                                                record.checked = e.target.checked;
                                            });
                                            setRecords(records);
                                        }}
                                    />
                                </TableCell> */}
                            <TableCell size='small' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Record ID</Typography>
                            </TableCell>
                            <TableCell size='small' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Patient ID</Typography>
                            </TableCell>
                            <TableCell size='medium' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Patient Name</Typography>
                            </TableCell>
                            <TableCell size='small' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Sex</Typography>
                            </TableCell>
                            <TableCell size='small' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Age</Typography>
                            </TableCell>
                            <TableCell size='medium' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Study Date</Typography>
                            </TableCell>
                            <TableCell size='medium' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Current Status</Typography>
                            </TableCell>
                            <TableCell size='medium' className='record-table-header-cell'>
                                <Typography className='table-cell-text'>Actions</Typography>
                            </TableCell>
                        </tr>
                    </TableHead>
                    {loading ? <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} /> :
                        <TableBody>
                            {records.map(record => (
                                <TableRecord
                                    record={record}
                                    onDeleteClick={() => {
                                        setDeleteDialogOpen(true);
                                        setDeleteRecordId(record.record_id);
                                        // setDeleteUniqueId(record.uniqueId);
                                    }}
                                    onCheckChange={(checked) => {
                                        console.log('checked', checked);
                                        const newRecords = [...records];
                                        const index = newRecords.findIndex((r) => r.recordId === record.recordId);
                                        newRecords[index].checked = checked;
                                        setRecords(newRecords);
                                    }}
                                    onViewerClick={(recordId) => {
                                        navigate(`/viewer/${recordId}`);
                                    }}
                                />
                            ))}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 50, { label: 'All', value: -1 }]}
                                colSpan={4}
                                count={numRecords}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={changePage}
                                onChangeRowsPerPage={changeRowsPerPage}
                                style={{ color: 'black' }}
                                onPageChange={(e, p) => {
                                    console.log('e', e, 'p', p);
                                    setPage(p);
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                <DeleteDialog
                    open={deleteDialogOpen}
                    recordId={deleteRecordId}
                    uniqueId={deleteUniqueId}
                    onClose={() => {
                        setDeleteDialogOpen(false);
                        getData();
                    }}
                />
            </div>
        </div>
    );
}        